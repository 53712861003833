@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

body {
  --bs-body-font-size: .875rem;
  --bs-body-line-height: 1.25rem;
  --bs-body-font-family: 'Inter', var(--bs-font-sans-serif);
}

.fontsize {
  font-size: var(--bs-body-font-size) !important;
}

.filter-container {
  max-width: 1000px;
}

.filter-item {
  min-width: 200px;
  max-width: 250px;
}

.sidebar-item {
  padding: 1rem 1.25rem;
}

.sidebar-item a {
  padding: 0rem 0.5rem !important;
}

.form-asterisk {
  font-size: 70%;
  color:  #C70039 ;
  vertical-align: top;
}

main {
  height: calc(100vh - 56px);
  height: -webkit-fill-available;
  max-height: calc(100vh - 56px);
  overflow-x: auto;
  overflow-y: hidden;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


.spinner-container {
  position: relative;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
}

.spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.5);
}

.sidebar {
  background-color: rgba(255, 255, 255);
}


.max-table-width {
  max-width: 640px;
}